.project-card-image {
  height: 100px;
  width: 100px;
}
.card-title {
  color: #000000;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin: 15px 0 0 0;
}
.card-subtitle {
  color: #666666;
  font-size: 17px;
  line-height: 1.5rem;
}
.project-card {
  display: flex;
  flex-direction: column;

  background-color: rgb(255, 255, 255);
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid rgba(211, 211, 211, 0.397);
  justify-content: flex-start;
}
.project-card-light {
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.project-card-dark {
  /* box-shadow: 0px 10px 30px -15px #d9dbdf; */
  box-shadow: rgba(210, 215, 211, 0.1) 0px 3px 6px,
    rgba(210, 215, 211, 0.23) 0px 3px 6px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.25s ease;
}

.project-card-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.project-title h5 {
  margin: 0 0 0 12px;
  padding: 0;
}

.project-detail {
  text-align: center;
}
.project-card-light:hover {
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px; */
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.project-card-dark:hover {
  box-shadow: #d9dbdf 10px 10px 10px -12px;
}
.project-image {
  position: relative;
  height: 250px;
  overflow: hidden;
  text-align: center;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem 1rem;
}

.project-card-footer span.project-tag {
  background: #55198b;
  color: #f5f2f4;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 0.75em;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.dark-mode.project-tag {
  background-color: var(--dark-theme-primary-color) !important;
  color: var(--black) !important;
}

@media (max-width: 768px) {
  .project-subtitle {
    font-size: 16px;
    text-align: center;
  }
}
