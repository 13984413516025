.footer-text {
  text-align: center;
  color: #868e96 !important;
}

.dark-mode {
  color: white !important;
}

.footer-div {
  margin-top: 2rem;
  padding-bottom: 1rem;
}

.blink {
  animation: blinkMe 1.6s linear infinite;
}
@keyframes blinkMe {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
